<template>
  <div class="page-container index-container">
    <!--欢迎大屏-->
    <div class="welcome-container">
      <!--图片模式-->
      <div class="image-model" v-if="bannerManage.type==='img'"></div>
      <!--视频模式-->
      <div class="video-model" v-if="bannerManage.type==='video'">
        <video width="100%" id="video" autoplay="" loop="" preload="" muted="" x-webkit-airplay="true" airplay="allow"
               webkit-playsinline="true" playsinline="true" src="" class="video1 video"
               draggable="true"></video>
      </div>
      <!--宣传文案-->
      <div class="doc flex flex-dr flex-center">
        <img :src="infoManage.logos.pc_logo_index_banner" alt="" v-if="infoManage.sLogoPc">
        <div class="text" v-if="infoManage.sLogoPc">{{ infoManage.sLogoPc }}</div>
        <!--滑动提示-->
        <div class="scroll-tip flex flex-dr flex-center" style="display: none">
          <span class="line"></span>
          <span class="tip">滑动</span>
        </div>
        <!--查看活动提示-->
        <div class="activity-tip flex flex-dr flex-center" v-if="false">
          <img class="button" src="@/assets/icon/view_activity.svg" alt="" @click="clickViewActivityBtn">
        </div>
      </div>
    </div>
    <!--产品介绍-->
    <div class="product-des-container flex flex-center flex-dr" v-if="experimentManage.show">
      <div class="title">{{ experimentManage.name }}</div>
      <div class="des">{{ experimentManage.subName }}</div>
      <div class="list flex flex-between">
        <div class="li" v-for="(experiment,experimentIndex) in experimentManage.list">
          <img :src="experiment.product_iconLeft" alt="" class="left-icon" v-if="experiment.product_iconLeft">
          <div class="bg">
            <img :src="experiment.product_bg" alt="">
          </div>
          <div class="info">
            <img class="avatar" :src="experiment.product_icon" alt="">
            <div class="flex flex-dr flex-center">
              <div class="title">{{ experiment.name }}</div>
              <div class="des-main word-hr ellipsis">{{ experiment.subName }}</div>
              <div class="des word-hr ellipsis" v-html="experiment.description"></div>
              <div class="buttons">
                <a class="button" href="javascript:void(0)" @click="clickViewExperiment(experiment,'')">查看详情</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="more" @click="clickExperimentMoreBtn">
        <span>查看更多教学资源</span>
        <i class="el-icon-arrow-down"></i>
      </div>

      <!--产品介绍-->
      <div v-if="experimentDialog" class="dialog-container experiment-dialog flex flex-dr flex-center">
        <div class="mask" @click="closeExperimentDialog"></div>
        <div class="dialog-box" style="border-radius: 4px">
          <div class="bg"></div>
          <img src="@/assets/icon/dialog_close.png" alt="关闭" class="close-icon" @click="closeExperimentDialog">
          <div class="info-box">
            <div class="header-box flex flex-start">
              <img :src="experimentInfo.experiment.product_icon" class="avatar" alt="">
              <div class="name-box">
                <div class="name">{{ experimentInfo.experiment.name }}</div>
                <div class="series">{{ experimentInfo.experiment.seriesName }}</div>
              </div>
              <div>
                <div class="enter-btn" v-if="!experimentDialogEnterBtn"
                     @click="clickEnterBtn()"></div>
                <transition name="slide-fade">
                  <div class="view-now-btn" v-if="experimentDialogEnterBtn">联系客服，免费体验：{{
                      infoManage.contactObj.phone
                    }}
                  </div>
                </transition>
              </div>
            </div>
            <div class="description-box">
              <div class="title">实验简介：</div>
              <div class="content">
                <p v-for="p in experimentInfo.experiment.experimentDescription.split('\n')">{{ p }}</p>
              </div>
            </div>
            <div class="view-box flex flex-between">
              <div class="video-box" v-if="experimentInfo.experiment.product_video">
                <video id="v1" loop="" preload="" muted="" x-webkit-airplay="true" airplay="allow"
                       webkit-playsinline="true" playsinline="true" :src="experimentInfo.experiment.product_video"
                       class="video1 video"
                       draggable="true"></video>
                <img src="@/assets/product/dialog/play.png" alt="" class="play-btn"
                     @click="e=>playVideo(e)">
              </div>
              <el-image v-if="!experimentInfo.experiment.product_video" class="video-box-no" fit="cover"
                        :src="experimentInfo.experiment.product_info_img+experimentDialogWaterMark"
                        :preview-src-list="experimentDialogPicList"></el-image>
              <div class="img-box flex flex-around flex-wrap">
                <el-image
                    class="img"
                    fit="cover"
                    :src="experimentInfo.experiment.product_info_img_1+experimentDialogWaterMark"
                    :preview-src-list="experimentDialogPicList">
                </el-image>
                <el-image
                    class="img"
                    fit="cover"
                    :src="experimentInfo.experiment.product_info_img_2+experimentDialogWaterMark"
                    :preview-src-list="experimentDialogPicList">
                </el-image>
                <el-image
                    class="img"
                    fit="cover"
                    :src="experimentInfo.experiment.product_info_img_3+experimentDialogWaterMark"
                    :preview-src-list="experimentDialogPicList">
                </el-image>
                <el-image
                    class="img"
                    fit="cover"
                    :src="experimentInfo.experiment.product_info_img_4+experimentDialogWaterMark"
                    :preview-src-list="experimentDialogPicList">
                </el-image>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--视频大窗播放-->
      <div v-if="experimentVideoDialog" class="dialog-container flex flex-dr flex-center experiment-video-dialog">
        <div class="mask" @click="experimentVideoDialog=false"></div>
        <div class="dialog-box">
          <img src="@/assets/icon/dialog_close.png" alt="关闭" class="close-icon" @click="experimentVideoDialog=false">
          <!--          <img src="@/assets/icon/video-view-btn.png" alt="查看详情" class="view-icon">-->
          <video controls id="v2" autoplay loop="" preload="" muted="" x-webkit-airplay="true" airplay="allow"
                 webkit-playsinline="true" playsinline="true" :src="experimentInfo.experiment.product_video"
                 class="video2 video"
                 draggable="true"></video>
        </div>
      </div>
    </div>
    <!--首页新闻-->
    <div class="news-container">
      <div class="focusBox">
        <div class="container" id="firstRotation">
          <div class="boxImage">
            <template v-for="(item,index) in newsManage.list">
              <div class="slide" style="display:block;z-index:2" v-if="index===0">
                <div class="img">
                  <a :href="'newsView?id='+item.officialWebNewsId"
                     target="_blank"><img
                      :src="item.headImgUrl"
                      width="100%"></a>
                </div>
                <img src=""/>
              </div>
              <div class="slide" v-if="index!==0">
                <div class="img">
                  <a :href="'newsView?id='+item.officialWebNewsId"
                     target="_blank"><img
                      :src="item.headImgUrl"
                      :data-src="item.headImgUrl"
                      width="100%"></a>
                </div>
                <img :src="item.headImgUrl"
                     data-src=""/>
              </div>
            </template>


          </div>
          <div class="boxTitle">

            <div :class="index===0?'title active':'title'" v-for="(item,index) in newsManage.list">
              <table cellpadding="0" cellspacing="0">
                <tbody>
                <tr>
                  <td>
                    <div class="txt" data-guid="">
                      <a :href="'newsView?id='+item.officialWebNewsId"
                         target="_blank">{{item.title}}</a>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>


          </div>
        </div>
      </div>
    </div>
    <!--关于-->
    <a href="#" name="about"></a>
    <div class="about-container" v-if="aboutManage.show">
      <div class="text-container">
        <div class="title">关于智云鸿道</div>
        <div class="line"></div>
        <div class="text">
          <p v-for="item in aboutManage.aboutTextArr">
            {{ item }}
          </p>
        </div>
        <div class="more-btn" @click="clickAboutMoreBtn">查看更多 ></div>
      </div>
    </div>
    <!--自定义楼层-->
    <div class="floor-container" :id="'floor-container-'+index" v-for="(item,index) in floorManage.list"
         v-if="item.show===true" :style='"background-image: url("+item.floor_img_big+")"'>
      <div class="text-container flex flex-dr flex-center">
        <div class="title">{{ item.title }}</div>
        <div class="content">
          <p v-for="p in item.content.split('\n')">{{ p }}</p>
        </div>
      </div>
    </div>
    <!--渠道合作-->
    <a href="#" name="cop"></a>
    <div class="cop-container flex flex-center flex-dr" v-if="copManage.show">
      <div class="title">渠道合作</div>
      <img :src="copManage.cop_big_img" alt="" class="des">
      <div class="text" style="text-align: center">
        <p v-for="p in copManage.copTextArr">{{ p }}</p>
      </div>
    </div>
    <!--成功案例-->
    <a href="#" name="success"></a>
    <div class="success-container" v-if="successManage.show">
      <div class="title">部分成功案例</div>
      <div class="sub-title">（排名不分先后）</div>
      <div class="list flex flex-around flex-wrap">
        <img :src="item.img" alt="" class="li" v-for="item in successManage.showList" v-if="item.img">
      </div>
      <div class="more" @click="drawSuccess().showAll()" v-if="successManage.hasMore">
        <span>查看更多</span>
        <i class="el-icon-arrow-down"></i>
      </div>
      <div class="more" @click="drawSuccess().close()" v-if="!successManage.hasMore">
        <span>收起</span>
        <i class="el-icon-arrow-up"></i>
      </div>
    </div>
  </div>
</template>

<script>
import {ConfigModel} from "@/model/erp/ConfigModel";
import $ from "jquery";
import 'objectFitPolyfill';
import {WebAnalyticsModel} from "../model/WebAnalyticsModel";

export default {
  name: "Index",
  data() {
    return {
      infoManage: {
        logos: {}
      },
      experimentManage: {},
      experimentDialog: false,
      experimentVideoDialog: false,
      experimentDialogPicList: [],
      experimentDialogEnterBtn: false,
      experimentInfo: {
        experiment: {}
      },
      newsManage: {
        list: []
      },
      bannerManage: {},
      aboutManage: {},
      copManage: {},
      successManage: {},
      floorManage: {},
      // 实验弹窗图 水印设置 5pm65LqR6bi_6YGT SE9OREFP
      // experimentDialogWaterMark: "?watermark/4/text/SE9OREFP/font/5a6L5L2T/fontsize/300/fill/IzAwMDAwMA==/dissolve/60/rotate/-25/uw/150/uh/150/resize/1",
      experimentDialogWaterMark: ""
    }
  },
  async mounted() {
    this.$store.dispatch('setIndex', true)

    // css定制化
    $("#header-container .index").addClass("active")
    // 获取配置
    let indexManage = await ConfigModel.getIndexConfig();
    this.infoManage = indexManage.infoManage
    this.experimentManage = indexManage.experimentManage
    this.newsManage = indexManage.newsManage
    this.bannerManage = indexManage.bannerManage
    this.aboutManage = indexManage.aboutManage
    this.copManage = indexManage.copManage
    this.successManage = indexManage.successManage
    this.successManage.list.splice(this.successManage.list.length - 1, 1)
    this.floorManage = indexManage.floorManage
    // 开始渲染
    this.$nextTick(() => {
      this.drawBanner()
      this.drawExperimentList()
      this.drawNewsList()
      this.drawAbout()
      this.drawFloor()
      this.drawCop()
      this.drawSuccess().init()
      // 监听窗口大小改变
      $(window).resize(() => {
        if (this.bannerManage.type === "video") {
          // 视频背景图改变
          let clientWidth = document.body.clientWidth
          $(".video-model video").css("width", clientWidth + "px")
          $(".video-model video").css("marginLeft", -clientWidth / 2 + "px")
        }
      });
      // 解决ie11 视频banner不全屏问题 因为object-fill不被支持
      let videoElement = document.querySelector('#video');
      objectFitPolyfill(videoElement);
    })


  },
  methods: {
    // 点击关于我们查看更多按钮
    clickAboutMoreBtn(){
      window.location.href = "/about"
    },
    // 点击查看活动按钮
    clickViewActivityBtn() {
      window.location.href = "/dsstudy"
    },
    // 点击-实验查看更多按钮
    clickExperimentMoreBtn() {
      window.location.href = "/product"
    },
    // 渲染banner
    drawBanner() {
      if (this.bannerManage.type === "video") {
        $(".video-model video").attr("src", this.bannerManage.banner_pc_video)
        let clientWidth = document.body.clientWidth
        $(".video-model video").css("width", clientWidth + "px")
        $(".video-model video").css("marginLeft", -clientWidth / 2 + "px")
      } else {
        $(".image-model").css("background-image", `url(${this.bannerManage.banner_pc_img})`)
      }
    },
    // 渲染实验列表
    drawExperimentList() {

    },
    // 渲染新闻列表
    drawNewsList() {
      var SwipersUl = $('#firstRotation')
      var SwipersChild = SwipersUl.find('.boxImage .slide');
      var SwipersList = SwipersUl.find('.boxTitle .title');
      var isAuto, _indexCur = "",
          _index = 0;
      var isFlag = true;
      $(".focusBox .boxTitle .title").each(function (index, element) {
        if ($(this).find(".txt").attr("data-guid") != "") {
          $(this).find(".txt a").attr("target", "");
          $(this).find(".txt a").attr("href", "javascript:vide_guid('" + $(this).find(".txt").attr("data-guid") + "')");
          SwipersChild.eq(index).find(".img a").attr("target", "");
          SwipersChild.eq(index).find(".img a").attr("href", "javascript:vide_guid('" + $(this).find(".txt").attr("data-guid") +
              "')");
          SwipersChild.eq(index).append('<a class="play" href="javascript:vide_guid(\'' + $(this).find(".txt").attr(
              "data-guid") + '\');"></a>')
        }
      });
      SwipersList.bind("mouseenter", function () {
        clearInterval(isAuto);
        _index = $(this).index();
        switch_ind01(_index - 1);
      })
      SwipersUl.hover(function () {
        clearInterval(isAuto);
      }, function () {
        if (SwipersUl.find('.boxVideo').css('display') == 'none') {
          clearInterval(isAuto);
          isAuto = setInterval(function () {
            switch_ind01(_index);
          }, 5000)
        }
      })
      isAuto = setInterval(function () {
        switch_ind01(_index);
      }, 5000)

      if (SwipersChild.eq(0).find('.nesting').length == 1) {
        SwipersChild.eq(0).find('.nesting ul li').each(function (index) {
          SwipersChild.eq(0).find('.nesting ul li').eq(index).find('.img_nes img').attr('src', SwipersChild.eq(0).find('.nesting ul li').eq(index).find('.img_nes img').attr('data-src'));
          SwipersChild.eq(0).find('.nesting .list01').css({
            'top': '-238px'
          });
          SwipersChild.eq(0).find('.nesting .list02').css({
            'bottom': '-238px'
          });
          SwipersChild.eq(0).find('.nesting .list01').stop().animate({
            'top': '0px'
          }, 300);
          SwipersChild.eq(0).find('.nesting .list02').stop().animate({
            'bottom': '0px'
          }, 300);
        })

      }

      function switch_ind01(id) {
        if (id == SwipersChild.length - 1) {
          _index = 0;
        } else {
          _index = id + 1;
        }
        if (isFlag && _index != _indexCur) {
          SwipersChild.stop(true, true);
          if (SwipersChild.eq(_index).find('.nesting').length == 1) {
            SwipersChild.eq(_index).find('.nesting ul li').each(function (index) {
              SwipersChild.eq(_index).find('.nesting ul li').eq(index).find('.img_nes img').attr('src', SwipersChild.eq(_index)
                  .find('.nesting ul li').eq(index).find('.img_nes img').attr('data-src'));
            })
            SwipersChild.eq(_index).find('.img a').addClass("noClick");
            SwipersChild.eq(_index).find('.img a').attr("href", "javascript:;");
            SwipersChild.eq(_index).find('.img a').removeAttr("target");
            SwipersChild.eq(_index).find('.nesting .list01').css({
              'top': '-238px'
            });
            SwipersChild.eq(_index).find('.nesting .list02').css({
              'bottom': '-238px'
            });
            SwipersChild.eq(_index).find('.nesting .list01').stop().animate({
              'top': '0px'
            }, 300);
            SwipersChild.eq(_index).find('.nesting .list02').stop().animate({
              'bottom': '0px'
            }, 300);
          }
          SwipersList.eq(_index).addClass('active').siblings().removeClass('active')
          SwipersChild.eq(_index).find('.img img').attr('src', SwipersChild.eq(_index).find('.img img').attr('data-src'));
          SwipersChild.eq(_indexCur).css({
            "z-index": "1"
          }).fadeOut(400);
          SwipersChild.eq(_index).css({
            "z-index": "2"
          }).fadeIn(400);
          _indexCur = _index;
        }
      }

      var SwipersUlWit = $('.boxImage .slide .nesting ul li .text').height();
      SwipersUl.find('.boxImage .slide .nesting ul li').hover(function () {
        $(this).find('.text').animate({
          'height': '160px'
        }, 500);
        $(this).find('.text').addClass('cur');
      }, function () {
        $(this).find('.text').animate({
          'height': SwipersUlWit
        }, 500);
        $(this).find('.text').removeClass('cur');
      });
    },
    // 点击实验查看详情按钮
    clickViewExperiment(experiment, seriesName) {
      this.experimentDialog = true
      document.body.style.overflow = 'hidden'
      experiment.seriesName = seriesName
      this.$set(this.experimentInfo, "experiment", experiment);
      this.experimentDialogEnterBtn = false
      this.experimentDialogPicList = [
        experiment.product_info_img_1 + this.experimentDialogWaterMark,
        experiment.product_info_img_2 + this.experimentDialogWaterMark,
        experiment.product_info_img_3 + this.experimentDialogWaterMark,
        experiment.product_info_img_4 + this.experimentDialogWaterMark,
      ]
      if (!experiment.product_video && experiment.product_info_img) {
        this.experimentDialogPicList.push(experiment.product_info_img + this.experimentDialogWaterMark)
      }
      setTimeout(() => {
        $(".experiment-dialog .bg").css("background-image", `url(${experiment.product_info_bg + this.experimentDialogWaterMark})`)
      }, 100)
    },
    // 关闭实验弹窗
    closeExperimentDialog() {
      this.experimentDialog = false
      document.body.style.overflow = ''
    },
    // 点击立即体验按钮
    clickEnterBtn() {
      this.experimentDialogEnterBtn = true
    },
    // 播放视频
    playVideo(e) {
      // this.videoPlay = true
      // document.querySelector("#v1").play()
      this.experimentVideoDialog = true
    },
    // 渲染关于我们
    drawAbout() {
      let aboutTextArr = this.aboutManage.aboutText.split("\n")
      this.$set(this.aboutManage, "aboutTextArr", aboutTextArr)
      $(".about-container").css("background-image", `url(${this.aboutManage.about_pc_bg})`)
      $(".about-container .text-container").css("background-image", `url(${this.aboutManage.about_pc_text_bg})`)
    },
    // 渲染自定义楼层
    drawFloor() {
    },
    // 渲染合作
    drawCop() {
      this.$set(this.copManage, "copTextArr", this.copManage.copText.split("\n"))
    },
    // 渲染成功案例
    drawSuccess() {
      let $this = this;
      return {
        init() {
          $this.$set($this.successManage, "pageNow", 0)
          $this.$set($this.successManage, "showList", [])
          // $this.$set($this.successManage, "hasMore", true)
          this.getNextPage()
        },
        // 显示所有
        showAll() {
          $this.successManage.showList = $this.successManage.list
          $this.$set($this.successManage, "hasMore", false)
        },
        // 获取下一页
        getNextPage() {
          let start = $this.successManage.pageNow * 8
          let end = ($this.successManage.pageNow + 1) * 8
          let list = $this.successManage.list.slice(start, end)
          if (list.length > 0) {
            list.forEach(li => {
              $this.successManage.showList.push(li)
            })
            let pageNow = $this.successManage.pageNow + 1;
            $this.$set($this.successManage, "pageNow", pageNow)
          }
          if ($this.successManage.showList.length === $this.successManage.list.length) {
            $this.$set($this.successManage, "hasMore", false)
          } else {
            $this.$set($this.successManage, "hasMore", true)
          }
        },
        // 收起按钮
        close() {
          this.init()
        }
      }
    },
  }
}
</script>

<style>
#header-container {
  background-color: rgba(0, 0, 0, 0.4);
}

#header-container .navs a {
  color: #fff !important;
  opacity: 0.6;
}

#header-container .navs a:hover {
  opacity: 1;
}

#header-container img.black {
  display: none;
}

#header-container .product-navs .list {
  display: none;
}
</style>
<style scoped lang="less">

.slide-fade-enter-active {
  transition: all 1.2s ease;
}

.slide-fade-leave-active {
  transition: all 1.2s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}

.slide-fade-enter, .slide-fade-leave-to {
  transform: scale(1.05);
  opacity: 0;
}

// 欢迎大屏
.welcome-container {
  position: relative;
  width: 100%;
  height: 100vh;
  // 图片模式
  .image-model {
    z-index: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    color: transparent;
    background-size: cover;
    background-position: 50% 50%;
    opacity: 1;
    -webkit-backface-visibility: hidden;
  }

  // 视频模式
  .video-model {
    z-index: 0;
    // 拉伸效果
    video.a {
      top: 0;
      right: 0;
      min-width: 100%;
      min-height: 100vh;
      object-fit: fill;
    }

    // 保持中间显示效果 视频要求 2560*780
    video {
      //width: 2560px;
      height: 100vh;
      position: absolute;
      top: 0;
      left: 50%;
      //margin-left: -1280px;
      object-fit: fill;
    }
  }

  // 宣传文案
  .doc {
    z-index: 10;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;

    img {
      display: block;
      width: 673px;
      height: 65px;
    }

    .text {
      font-size: 27px;
      margin-top: 28px;
      text-align: center;
      margin-left: -12px;
    }

    color: #fff;
    // 滑动提示
    .scroll-tip {
      position: absolute;
      bottom: 10%;
      text-align: center;

      .line {
        width: 2px;
        height: 35px;
        border-left: 2px solid #fff;
        animation: a_banner_s_line 3s 0.3s linear both infinite;
        -webkit-animation: a_banner_s_line 3s 0.3s linear both infinite;
        @keyframes a_banner_s_line {
          0% {
            transform: scale(1, 1);
            -webkit-transform: scale(1, 1);
            -ms-transform: scale(1, 1);
            -moz-transform: scale(1, 1);
            -o-transform: scale(1, 1);
            transform-origin: 50% 100%;
            -webkit-transform-origin: 50% 100%;
            -ms-transform-origin: 50% 100%;
            -moz-transform-origin: 50% 100%;
            -o-transform-origin: 50% 100%;
            opacity: 1;
          }
          20% {
            transform: scale(1, 0.01);
            -webkit-transform: scale(1, 0.01);
            -ms-transform: scale(1, 0.01);
            -moz-transform: scale(1, 0.01);
            -o-transform: scale(1, 0.01);
            transform-origin: 50% 100%;
            -webkit-transform-origin: 50% 100%;
            -ms-transform-origin: 50% 100%;
            -moz-transform-origin: 50% 100%;
            -o-transform-origin: 50% 100%;
            opacity: 1;
          }
          30% {
            transform: scale(1, 0.01);
            -webkit-transform: scale(1, 0.01);
            -ms-transform: scale(1, 0.01);
            -moz-transform: scale(1, 0.01);
            -o-transform: scale(1, 0.01);
            transform-origin: 50% 100%;
            -webkit-transform-origin: 50% 100%;
            -ms-transform-origin: 50% 100%;
            -moz-transform-origin: 50% 100%;
            -o-transform-origin: 50% 100%;
            opacity: 0;
          }
          31% {
            transform: scale(1, 0.01);
            -webkit-transform: scale(1, 0.01);
            -ms-transform: scale(1, 0.01);
            -moz-transform: scale(1, 0.01);
            -o-transform: scale(1, 0.01);
            transform-origin: 50% 0;
            -webkit-transform-origin: 50% 0;
            -ms-transform-origin: 50% 0;
            -moz-transform-origin: 50% 0;
            -o-transform-origin: 50% 0;
            opacity: 0;
          }
          50% {
            transform: scale(1, 1);
            -webkit-transform: scale(1, 1);
            -ms-transform: scale(1, 1);
            -moz-transform: scale(1, 1);
            -o-transform: scale(1, 1);
            transform-origin: 50% 0;
            -webkit-transform-origin: 50% 0;
            -ms-transform-origin: 50% 0;
            -moz-transform-origin: 50% 0;
            -o-transform-origin: 50% 0;
            opacity: 1;
          }
          100% {
            transform: scale(1, 1);
            -webkit-transform: scale(1, 1);
            -ms-transform: scale(1, 1);
            -moz-transform: scale(1, 1);
            -o-transform: scale(1, 1);
            transform-origin: 50% 100%;
            -webkit-transform-origin: 50% 100%;
            -ms-transform-origin: 50% 100%;
            -moz-transform-origin: 50% 100%;
            -o-transform-origin: 50% 100%;
            opacity: 1;
          }
        }
      }

      .tip {
        font-size: 14px;
        margin-top: 10px;
      }
    }

    .activity-tip {
      position: absolute;
      bottom: 10%;
      text-align: center;

      .button {
        opacity: 0.6;
        cursor: pointer;
        width: 140px;

        &:hover {
          opacity: 1;
        }
      }
    }
  }
}

// 产品介绍
.product-des-container {
  position: relative;
  margin-top: 80px;

  > .title {
    font-size: 26px;
    font-weight: bold;
  }

  > .des {
    font-size: 16px;
    font-weight: 300;
    margin-top: 12px;
  }

  .list {
    margin-top: 50px;
    width: 1280px;
    align-items: normal;

    .li {
      margin-bottom: 30px;
      position: relative;

      &:hover {
        .bg {
          img {
            transition: all 0.5s linear;
            transform: scale(1.1);
          }
        }

        .info {
          box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);

          .avatar {
            margin-top: -30px;
            opacity: 0;
          }

          .des-main {
            opacity: 0;
          }

          .title {
            margin-top: -30px;
            margin-bottom: 0px;
          }

          .des {
            display: -webkit-box;
          }

          .buttons {
            display: block;
          }
        }

      }

      img.left-icon {
        position: absolute;
        width: 60px;
        height: 60px;
        top: -10px;
        left: -10px;
        z-index: 10;
      }

      .bg {
        z-index: 1;
        width: 400px;
        height: 250px;
        overflow: hidden;

        img {
          width: 400px;
          height: 250px;
          display: block;
          transition: all 0.5s linear;
          transform: scale(1);
        }
      }

      .info {
        position: relative; // 解决margin-top负数 和同级div的z-index问题
        padding: 30px;
        height: 240px;
        z-index: 2;
        width: 350px;
        margin: 0 auto;
        margin-top: -60px;
        text-align: center;
        background: #fff;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
        border-bottom: 3px solid transparent;
        transition: all 0.2s linear;
        overflow: hidden;

        .avatar {
          display: inline-block;
          width: 80px;
          height: 80px;
          position: relative;
          margin-top: 0px;
          transition: all 0.2s linear;
          border-radius: 4px;
          box-shadow: 1px 3px 6px 2px #dedede;
        }

        .title {
          position: relative;
          font-size: 22px;
          font-weight: bold;
          margin-top: 18px;
          margin-bottom: 10px;
          transition: all 0.2s linear;
        }

        .des-main {
          transition: all 0.2s linear;
          color: #666;
          font-size: 16px;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          display: -webkit-box;
        }

        .des {
          font-weight: 300;
          font-size: 16px;
          display: none;
          color: #666;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          max-width: 280px;
        }

        .buttons {
          display: none;
          text-align: center;
          margin-top: 19px;
        }

        .button {
          width: 100px;
          line-height: 28px;
          border: 1px solid #d8d8d8;
          border-radius: 15px;
          text-align: center;
          font-size: 14px;
          color: #666666;
          position: relative;
          cursor: pointer;
          transition: all 0.2s linear;
          text-decoration: none;
          padding: 5px 20px;

          &:hover {

          }
        }


      }
    }
  }

  .more {
    text-align: center;
    cursor: pointer;
    transition: all 0.3s linear;
    margin: 0 auto;
    margin-top: 0px;
    color: #707070;

    &:hover {
      transform: scale(1.1);
    }

    span {
      font-weight: 400;
      line-height: 21px;
      color: #707070;
      font-size: 16px;
      margin-right: 3px;
    }

    img {
      width: 22px;
      height: 22px;
      display: inline-block;
      margin-left: 4px;
    }
  }

  .experiment-dialog {
    border-radius: 4px;
    z-index: 2000; // 解决被遮挡问题
    .dialog-box {
      border-radius: 4px;
    }

    .bg {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      width: 100%;
      height: 330px;
      background-size: cover;
      background-position: 50% 50%;
    }

    .close-icon {
      display: inline-block;
      width: 24px;
      height: 24px;
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;
    }

    .info-box {
      border-radius: 4px;
      padding: 0px 80px;
    }

    .header-box {
      position: relative;
      margin-top: -115px;

      .avatar {
        width: 134px;
        height: 134px;
        border-radius: 4px;
        box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.2);
        margin-right: 20px;
      }

      .name-box {
        .name {
          font-size: 33px;
          font-weight: bold;
          line-height: 44px;
          color: #FFFFFF;
          text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.5);
          opacity: 0.9;
        }

        .series {
          font-size: 16px;
          font-weight: 400;
          line-height: 21px;
          color: #FFFFFF;
          text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);
          opacity: 0.9;
          margin-bottom: 8px;
        }
      }

      .enter-btn {
        margin-left: 300px;
        width: 97px;
        height: 29px;
        background-size: cover;
        background-position: 50% 50%;
        -webkit-backface-visibility: hidden;
        background-image: url("../assets/product/dialog/enter-btn.png");
        cursor: pointer;
      }

      .view-now-btn {
        display: inline-block;
        padding: 10px 30px;
        background-color: #ff3829;
        color: #fff;
        font-size: 14px;
        margin-left: 200px;
        border-radius: 20px;
      }
    }

    .description-box {
      font-size: 16px;
      font-weight: 400;
      line-height: 21px;
      color: #333333;
      margin-top: 20px;

      .title {
        font-size: 16px;
        line-height: 21px;
        font-weight: 400;
        color: #333;
      }

      .content {
        margin-top: 5px;
        font-size: 14px;
        text-indent: 2em;
        font-weight: 400;
        line-height: 19px;
        color: #333333;
      }
    }

    .view-box {
      width: 100%;
      margin-top: 25px;
      margin-bottom: 35px;

      .video-box {
        width: 337px;
        height: 223px;
        position: relative;

        video {
          position: absolute;
          left: 0;
          top: 0;
          width: 337px;
          height: 223px;
          object-fit: fill;
          z-index: 1;
        }

        .play-btn {
          position: absolute;
          // 上下左右居中
          left: 50%;
          top: 50%;
          transform: translateX(-50%) translateY(-50%);
          width: 54px;
          height: 54px;
          cursor: pointer;
          z-index: 2;
        }
      }

      .video-box-no {
        width: 337px;
        height: 223px;
        background-size: cover;
        background-position: 50% 50%;
        -webkit-backface-visibility: hidden;
      }

      .img-box {
        width: 695px;

        .img {
          width: 334px;
          height: 106px;
          cursor: pointer;

          &:nth-child(1), &:nth-child(2) {
            margin-bottom: 11px;
          }
        }
      }
    }
  }

  .experiment-video-dialog {
    z-index: 2001;

    .dialog-box {
      width: 1200px;
      height: 720px;
    }

    .close-icon {
      display: inline-block;
      width: 24px;
      height: 24px;
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;
      z-index: 3;
    }

    .view-icon {
      display: inline-block;
      width: 100px;
      height: 30px;
      position: absolute;
      bottom: 80px;
      right: 25px;
      cursor: pointer;
      z-index: 3;
    }

    video {
      width: 1200px;
      height: 720px;
      object-fit: fill;
    }
  }
}

// 关于
.about-container {
  margin-top: 120px;
  position: relative;
  width: 100%;
  height: 670px;
  color: transparent;
  background-size: cover;
  background-position: 50% 50%;
  opacity: 1;
  -webkit-backface-visibility: hidden;

  .text-container {
    position: absolute;
    top: -80px;
    right: 130px;
    width: 840px;
    height: 640px;
    background-size: cover;
    background-position: 50% 50%;
    opacity: 1;
    -webkit-backface-visibility: hidden;
    color: #fff;
    padding-left: 120px;
    padding-right: 120px;

    .title {
      font-size: 26px;
      line-height: 35px;
      font-weight: bold;
      margin-top: 90px;
    }

    .line {
      width: 61px;
      height: 11px;
      background: #FFFFFF;
      opacity: 1;
      margin-top: 15px;
      margin-bottom: 35px;
    }

    .text {
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;

      p {

      }
    }
  }

  .more-btn{
    width: 80px;
    cursor: pointer;
    height: 21px;
    font-size: 16px;
    font-weight: 400;
    line-height: 42px;
    color: #FFFFFF;
    position: absolute;
    bottom: 80px;
    right: 132px;
  }
}

// 自定义楼层
.floor-container {
  position: relative;
  width: 100%;
  height: 760px;
  background-size: cover;
  background-position: 50% 50%;
  opacity: 1;
  -webkit-backface-visibility: hidden;

  .text-container {
    color: #fff;
    font-size: 16px;
    line-height: 27px;
    position: absolute;
    width: 400px;
    height: 650px;

    .title {
      margin-bottom: 30px;
    }
  }

  &:nth-child(even) {
    .text-container {
      left: 200px;
    }
  }

  &:nth-child(odd) {
    .text-container {
      right: 200px;
    }
  }
}

// 渠道合作
.cop-container {
  margin-top: 80px;

  .title {
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
    color: #707070;
    margin-bottom: 40px;
  }

  img.des {
    margin-bottom: 40px;
  }

  .text {
    width: 835px;
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    color: #707070;
  }
}

// 成功案例
.success-container {
  max-width: 1200px;
  margin: 0 auto;
  margin-bottom: 80px;
  margin-top: 60px;


  .title {
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
    color: #707070;
    margin-bottom: 0px;
    text-align: center;
  }

  .sub-title {
    font-size: 15px;
    font-weight: 400;
    line-height: 32px;
    color: #999;
    margin-bottom: 40px;
    text-align: center;
  }

  .list {
    text-align: center;
  }

  .list {
    display: grid;
    justify-content: space-between;
    grid-template-columns: repeat(auto-fill, 285px);
    grid-gap: 10px;
  }


  .li {
    width: 285px;
    height: 140px;
    margin-top: 10px;
    //margin-right: 10px;
    background-size: cover;
    background-position: 50% 50%;
    -webkit-backface-visibility: hidden;
    opacity: 1;
    transform: scale(1);
    border-radius: 4px;
    box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.1);

    &:hover {
      transition: all 0.3s linear;
      transform: scale(1.03);
    }
  }

  .more {
    text-align: center;
    cursor: pointer;
    transition: all 0.3s linear;
    width: 100px;
    margin: 0 auto;
    margin-top: 30px;
    color: #707070;

    &:hover {
      transform: scale(1.1);
    }

    span {
      font-weight: 400;
      line-height: 21px;
      color: #707070;
      font-size: 16px;
      margin-right: 3px;
    }

    img {
      width: 22px;
      height: 22px;
      display: inline-block;
      margin-left: 4px;
    }
  }
}

// 新闻列表
.news-container {
  margin-top: 80px;
  padding-bottom: 80px;
}
</style>
